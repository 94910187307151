<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="cropped"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            class="me-3 mt-5"
            @click="$refs.refInputEl.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload new photo</span>
          </v-btn>

          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png,.jpg"
            :hidden="true"
            v-on:click="$refs.refInputEl.value = ''"
            v-on:change="croppie"
          />

          <v-btn
            color="error"
            outlined
            class="mt-5"
            @click="resett()"
          >
            Reset
          </v-btn>
          <p class="text-sm mt-5">
            Allowed JPG or PNG
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
              v-if="!for_update"
            >
              <v-select
                v-model="category_id"
                dense
                outlined
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                :rules="rules.combobox_rule"
                @change="selected_category"
              ></v-select>
            </v-col>

            <v-col
              md="4"
              cols="12"
              v-if="!for_update"
            >
              <v-select
                v-model="branch_id"
                dense
                outlined
                label="Branch/Department"
                :items="branch_items"
                item-value="id"
                item-text="branch_code"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="4"
              cols="12"
              v-if="!for_update"
            >
              <v-select
                v-model="employee_position_id"
                dense
                outlined
                label="Position"
                :items="position_items"
                item-value="id"
                item-text="position"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-if="position==='HUMAN RESOURCE'"
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-if="position==='HUMAN RESOURCE'"
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      readonly
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-if="position==='HUMAN RESOURCE'"
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      readonly
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="religion"
                      label="Religion"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-select
                      v-model="region_id"
                      dense
                      outlined
                      label="Address By Region"
                      :items="region_items"
                      item-value="id"
                      item-text="region"
                      :rules="rules.combobox_rule"
                      @change="selected_region"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-select
                      v-model="province_id"
                      dense
                      outlined
                      label="Address By Province"
                      :items="province_items"
                      item-value="id"
                      item-text="province"
                      :rules="rules.combobox_rule"
                      @change="selected_province"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-select
                      v-model="branch_city_id"
                      dense
                      outlined
                      label="Address By City"
                      :items="branch_city_items"
                      item-value="id"
                      item-text="city"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="address"
                      label="Specify Address"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="email_address"
                      label="Email Address"
                      dense
                      outlined
                      :rules="rules.email_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="contact_no"
                      label="Contact #"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">EMPLOYMENT DATA</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row class="mt-3">
                  <v-col
                    md="4"
                    cols="12"
                    v-if="!for_update"
                  >
                    <v-text-field
                      v-model="date_of_employment"
                      label="Date of Eployment"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                    v-if="!for_update"
                  >
                    <v-text-field
                      v-model="compensation_rate"
                      label="Compensation Rate"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                    v-if="!for_update"
                  >
                    <v-checkbox
                      v-model="is_allowance_only"
                      label="Is Allowance"
                      dense
                      outlined
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    v-if="!for_update"
                  >
                    <v-select
                      v-model="bank_type_1"
                      dense
                      outlined
                      label="(Primary) Bank Type"
                      :items="['EASTWEST','BDO NETWORK','OTHERS']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    v-if="!for_update"
                  >
                    <v-text-field
                      v-model="bank_account_1"
                      dense
                      label="(Primary) Account Name"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    v-if="!for_update"
                  >
                    <v-text-field
                      v-model="bank_account_no_1"
                      dense
                      label="(Primary) Account Number"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-card
                      flat
                    >
                      <v-toolbar dense dark>
                        <v-toolbar-title><h6 class="font-weight-light">BENEFITS</h6>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="mt-3">
                        <v-row>
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="sss_no"
                              label="SSS #"
                              dense
                              outlined
                              :rules="rules.default_max_45_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="ph_no"
                              label="Philhealth #"
                              dense
                              outlined
                              :rules="rules.default_max_45_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="pag_ibig_no"
                              label="Pag-ibig #"
                              dense
                              outlined
                              :rules="rules.default_max_45_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="tin_no"
                              label="TIN #"
                              dense
                              outlined
                              :rules="rules.default_max_45_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>

                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-card
                      flat
                    >
                      <v-toolbar dense dark>
                        <v-toolbar-title><h6 class="font-weight-light">IN CASE OF EMERGENCY
                        </h6>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-col
                          md="4"
                          cols="12"
                        ></v-col>
                        <v-row>
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="emergency_name"
                              label="Name"
                              dense
                              outlined
                              :rules="rules.default_max_100_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="emergency_address"
                              label="Address"
                              dense
                              outlined
                              :rules="rules.default_max_255_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-text-field
                              v-model="emergency_contact_no"
                              label="Contact #"
                              dense
                              outlined
                              :rules="rules.default_max_45_character_and_no_empty_rule"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">126009
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="!for_update">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_employee"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" v-if="for_update">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="update_employee"
                v-if="!saving"
              >
                Update changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>


    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      show: false,
      avatarImg: require('@/assets/images/avatars/1.png'),

      saving: false,
      alert: false,
      alert_message: '',
      croppieImage: '',
      cropped: require('@/assets/images/avatars/1.png'),

      category_id: '',
      category_items: [],
      branch_id: '',
      branch_items: [],
      employee_position_id: '',
      position_items: [],
      last_name: '',
      first_name: '',
      middle_name: '',
      date_of_birth: '',
      gender: '',
      civil_status: '',
      religion: '',
      date_of_employment: '',
      compensation_rate: 0,
      is_allowance_only: false,
      bank_type_1: '',
      bank_account_1: '',
      bank_account_no_1: '',
      bank_type_2: '',
      bank_account_2: '',
      bank_account_no_2: '',
      bank_type_3: '',
      bank_account_3: '',
      bank_account_no_3: '',
      sss_no: '',
      ph_no: '',
      pag_ibig_no: '',
      tin_no: '',
      emergency_name: '',
      emergency_address: '',
      emergency_contact_no: '',
      address: '',
      region_id: '',
      province_id: '',
      branch_city_id: '',
      region_items: [],
      province_items: [],
      branch_city_items: [],
      email_address: '',
      contact_no: '',
    }
  }

  export default {
    props: {
      for_update: Boolean,
      information_details: Object,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },

    data() {
      return initialState()
    },
    mounted() {
      if (this.for_update) {
        this.last_name = this.information_details.last_name
        this.first_name = this.information_details.first_name
        this.middle_name = this.information_details.middle_name
        this.category_id = this.information_details.category_id
        this.branch_id = this.information_details.branch_id
        this.employee_position_id = this.information_details.employee_position_id
        this.date_of_birth = this.information_details.date_of_birth
        this.gender = this.information_details.gender
        this.civil_status = this.information_details.civil_status
        this.religion = this.information_details.religion
        this.date_of_employment = this.information_details.date_of_employment
        this.compensation_rate = this.information_details.compensation_rate
        this.is_allowance_only = this.information_details.is_allowance_only
        this.bank_type_1 = this.information_details.bank_type_1
        this.bank_account_1 = this.information_details.bank_account_1
        this.bank_account_no_1 = this.information_details.bank_account_no_1
        this.sss_no = this.information_details.sss_no
        this.ph_no = this.information_details.ph_no
        this.pag_ibig_no = this.information_details.pag_ibig_no
        this.tin_no = this.information_details.tin_no
        this.emergency_name = this.information_details.emergency_name
        this.emergency_address = this.information_details.emergency_address
        this.emergency_contact_no = this.information_details.emergency_contact_no
        this.email_address = this.information_details.email_address
        this.contact_no = this.information_details.contact_no
        this.address = this.information_details.address
        this.region_id = this.information_details.region_id
        this.province_id = this.information_details.province_id
        this.branch_city_id = this.information_details.branch_city_id
      }
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['initialize_new_employee', 'is_employee_already_encoded', 'register_new_employee', 'update_info']),
      initialize_data() {
        this.initialize_new_employee()
          .then(response => {
            this.category_items = response.data[0].category
            this.region_items = response.data[0].address
            if (this.for_update) {
              this.selected_region()
              this.selected_province()
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index >= 0) {
            this.position_items = this.category_items[index].employee_position
            this.branch_items = this.category_items[index].branch
            this.employee_position_id = ''
            this.branch_id = ''
          }
        }
      },
      selected_region() {
        if (this.region_items.length > 0) {
          var index = this.region_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.region_id)
          if (index >= 0) {
            this.province_items = this.region_items[index].province
            if (!this.for_update) {
              this.province_id = ''
              this.branch_city_id = ''
            }
          }
        }
      },
      selected_province() {
        if (this.province_items.length > 0) {
          var index = this.province_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.province_id)
          if (index >= 0) {
            this.branch_city_items = this.province_items[index].city
            if (!this.for_update) {
              this.branch_city_id = ''
            }
          }
        }
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (this.croppieImage === '') {
            this.alert = true
            this.alert_message = 'Please Upload Photo'
            this.saving = false
            proceed = false
          }
          if (proceed) {
            const data = new FormData()
            data.append('last_name', this.last_name.toUpperCase());
            data.append('first_name', this.first_name.toUpperCase());
            data.append('middle_name', this.middle_name.toUpperCase());
            data.append('category_id', this.category_id);
            this.is_employee_already_encoded(data)
              .then(response => {
                if (response.data === 1) {
                  this.alert = true
                  this.alert_message = 'Employee Already Encoded in this Category!'
                  this.saving = false
                } else {
                  data.append('branch_id', this.branch_id);
                  data.append('employee_position_id', this.employee_position_id);
                  data.append('date_of_birth', this.date_of_birth);
                  data.append('gender', this.gender);
                  data.append('civil_status', this.civil_status);
                  data.append('religion', this.religion.toUpperCase());
                  data.append('date_of_employment', this.date_of_employment);
                  data.append('compensation_rate', this.compensation_rate);
                  data.append('is_allowance_only', this.is_allowance_only);
                  data.append('bank_type_1', this.bank_type_1);
                  data.append('bank_account_1', this.bank_account_1.toUpperCase());
                  data.append('bank_account_no_1', this.bank_account_no_1);
                  data.append('sss_no', this.sss_no);
                  data.append('ph_no', this.ph_no);
                  data.append('pag_ibig_no', this.pag_ibig_no);
                  data.append('tin_no', this.tin_no);
                  data.append('emergency_name', this.emergency_name.toUpperCase());
                  data.append('emergency_address', this.emergency_address.toUpperCase());
                  data.append('emergency_contact_no', this.emergency_contact_no);
                  data.append('email_address', this.email_address);
                  data.append('contact_no', this.contact_no);
                  data.append('address', this.address.toUpperCase());
                  data.append('region_id', this.region_id);
                  data.append('province_id', this.province_id);
                  data.append('branch_city_id', this.branch_city_id);
                  data.append('profile_picture', this.croppieImage);
                  this.register_new_employee(data)
                    .then(response => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: response.data,
                      })
                      this.reset()
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      update_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (this.croppieImage === '') {
            this.alert = true
            this.alert_message = 'Please Upload Photo'
            this.saving = false
            proceed = false
          }
          if (proceed) {
            const data = new FormData()
            data.append('id', this.information_details.id);
            data.append('id_no', this.information_details.id_no);
            data.append('last_name', this.last_name.toUpperCase());
            data.append('first_name', this.first_name.toUpperCase());
            data.append('middle_name', this.middle_name.toUpperCase());
            data.append('category_id', this.category_id);
            data.append('branch_id', this.branch_id);
            data.append('employee_position_id', this.employee_position_id);
            data.append('date_of_birth', this.date_of_birth);
            data.append('gender', this.gender);
            data.append('civil_status', this.civil_status);
            data.append('religion', this.religion.toUpperCase());
            data.append('date_of_employment', this.date_of_employment);
            data.append('compensation_rate', this.compensation_rate);
            data.append('is_allowance_only', this.is_allowance_only);
            data.append('bank_type_1', this.bank_type_1);
            data.append('bank_account_1', this.bank_account_1.toUpperCase());
            data.append('bank_account_no_1', this.bank_account_no_1);
            data.append('sss_no', this.sss_no);
            data.append('ph_no', this.ph_no);
            data.append('pag_ibig_no', this.pag_ibig_no);
            data.append('tin_no', this.tin_no);
            data.append('emergency_name', this.emergency_name.toUpperCase());
            data.append('emergency_address', this.emergency_address.toUpperCase());
            data.append('emergency_contact_no', this.emergency_contact_no);
            data.append('email_address', this.email_address);
            data.append('contact_no', this.contact_no);
            data.append('address', this.address.toUpperCase());
            data.append('region_id', this.region_id);
            data.append('province_id', this.province_id);
            data.append('branch_city_id', this.branch_city_id);
            data.append('profile_picture', this.croppieImage);
            this.update_info(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.reset()
                this.$emit('data', response)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
